<template>
	<div class="nav-content m-b0">
		<banner :bannerTitle="bannerTitle"></banner>
		<div class="errorhint-box height510">
			<div class="login-tip">
				<p>{{title}}</p>
				<span>{{desc}}</span>
			</div>
			<div class="operation">
				<a class="btn1" v-if="url != ''" :href="url">返回上一页</a>
				<router-link class="btn2" to="/">进入首页</router-link>
				<!-- <a href="/" class="btn2">进入首页</a>  target="_blank" -->
			</div>
		</div>
	</div>
</template>
<script>
import banner from '../components/banner.vue'
export default {
  components: {
    banner
  },
  data(){
    return{
			bannerTitle: '企业技术创新力在线自测系统', // banner背景图title
			// 错误状态码
      code:0,
			// 标题
      title:'',
			// 提示内容
      desc:'',
			url: '',
    }
  },
  created(){
		// console.log(this.$route.query.title)
    // 获取状态码
		this.url = ''
    this.code = this.$route.params.code
    // 判断状态码,500,400,其他情况均为404
    if(this.code == 500){
			this.title = '系统繁忙'
			this.desc = '系统繁忙，请稍后再试'
    }else if(this.code == 404){
			this.title = '未找到网页！'
			this.desc = '所访问的页面已不存在或已被管理员删除。'
    }else if(this.code == 400){
			this.title = '请求错误'
			this.desc = '错误的请求或域名已不存在'
    }else{
			// 直接跳转到404页面，不会留下进程
			// this.$router.replace('/err/404')
			this.title = this.$route.query.title
			this.desc = this.$route.query.desc
			this.url = this.$route.query.url
    }
  },
  methods:{
    // 返回上一页
    returns(){
			this.$router.go(-1)
    }
  }
    
}
</script>
<style scoped>
.nav-content{margin: 0 auto 60px;/* padding-top: 20px; */background: rgb(245, 245, 245);/* background: linear-gradient(180deg, #fff 0%, #fff 60%, #e6f4ff 100%); */}
.m-b0{margin-bottom: 0;}

.errorhint-box{margin: auto;/* padding-bottom: 40px; */width: 1200px;}
.height510{height: 510px;box-sizing: border-box;}
/* 大图 */
.login-tip{display: flex;flex-wrap: wrap;padding: 40px 0;box-sizing: border-box;justify-content: flex-start;font-size: 16px;}
/* 提示文字 */
.login-tip>p{margin-bottom: 10px;width:100%;text-align:left;color: #000000;font-weight: bold;font-size: 21px;}
/* 按钮部分 */
.operation{display: flex;margin-left: 0;}
.operation a{width: 200px;height: 40px;line-height: 40px;font-weight: 400;font-size: 16px;cursor: pointer;}
.btn1{margin-right: 30px;background-color: #CCCCCC;color: #000000;}
.btn2{background-color: #43C8DB;color: #ffffff;}
</style>
